<script setup lang="ts">
import type { HomeBanners } from "@/types";

const props = defineProps<{ banner: HomeBanners[number] }>();
const localPath = useLocalePath();

const handleActionButton = () => {
	navigateTo(localPath(props?.banner?.buttonLink ?? ""));
};
</script>

<template>
	<client-only>
		<MBanner
			:bg-img="banner.backgroundImage"
			:bg-img2x="banner.backgroundImage2x"
			:bg-color="banner.background"
			:image="banner.image"
			:image-mobile="banner.imageMobile"
			:second-image="banner.secondImage"
			:second-image-mobile="banner.secondImageMobile"
			:type="banner.type"
		>
			<template #default>
				<AText :size="{ full: 24, md: 14 }" class="text-shadow" :modifiers="['medium']">
					{{ banner?.subTitle ?? "" }}
				</AText>
			</template>
			<template #description>
				<AText :size="{ full: 40, md: 24 }" class="text-tlalnepantla text-shadow" :modifiers="['uppercase', 'bold']">
					{{ banner?.title ?? "" }}
				</AText>
			</template>
			<template #actions>
				<AButton variant="primary" class="button" size="xl" @click="handleActionButton">
					<AText :size="{ full: 20, md: 16 }" class="text-tlalnepantla" :modifiers="['semibold']">
						{{ banner?.buttonText ?? "" }}
					</AText>
				</AButton>
			</template>
		</MBanner>
	</client-only>
</template>

<style lang="scss" scoped>
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: 32px;

			@include media-breakpoint-down(md) {
				padding: 16px;
			}
		}

		&__desc {
			align-items: flex-start;
			text-align: left;
			max-width: 480px;

			@include media-breakpoint-down(md) {
				max-width: 290px;
				gap: 0;
			}
		}

		&__actions {
			margin-top: 32px;
			width: 280px;

			@include media-breakpoint-down(md) {
				margin-top: 16px;
				width: 133px;
			}

			.button {
				width: 100%;

				@include media-breakpoint-down(md) {
					height: 40px;
					border-radius: 8px;
				}
			}
		}
	}
}
</style>
